import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import axios from 'axios'
import Layout from '../components/Layout'

import alertimage from '../img/mobile-mock-web.svg'

class Homepage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            first_name: '',
            last_name: '',
            street_address: '',
            city: '',
            zip: '',
            mobile: '',
            email: '',
            formError: [],
            formSuccess: '',
        }
    }

    submit = (event) => {
        event.preventDefault()
        this.setState( { formSuccess: '', formError: [] }, this.handleFormEntry )


    }

    handleFormEntry = () => {
        // console.log('SUBMIT')
        // console.log('name: '+this.state.name)
        // console.log('email: '+this.state.email)
        // console.log('message: '+this.state.message)
        // console.log('agreement: '+this.state.agreement)
        // console.log('response: '+this.state.captcha)

        if( this.state.first_name     === '' ||
            this.state.last_name    === '' ||
            this.state.street_address    === '' ||
            this.state.city  === '' ||
            this.state.zip  === '' ||
            this.state.mobile  === '' ||
            this.state.email  === ''
          ) {
            this.setState({ formError: this.state.formError.concat(["Form not filled out completely."]) })
        } else {
            let formData = new FormData() 
            formData.set('first_name', this.state.first_name)
            formData.set('last_name', this.state.last_name)
            formData.set('street_address', this.state.street_address)
            formData.set('city', this.state.city)
            formData.set('zip', this.state.zip)
            formData.set('mobile', this.state.mobile)
            formData.set('email', this.state.email)
            formData.set('state', 'TX')
            formData.set('client_id', '37')

            axios.post('https://inten.touchstonedistrictservices.com/api/account-registration', formData, 
            //axios.post('http://touchstone-server.test/mailer', formData,
                {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    }
                }).then( function(response) {
                    console.log('Response');
                    if(response.data.success === true ) {
                      this.clearForm()
                        this.setState({ formSuccess: "Your account has been registered." })
                    } else {
                        this.setState({ formError: this.state.formError.concat(["Something went wrong, check your entry and try again."]) })
                    }

            }.bind(this) ).catch( function(error) {
                console.log(error.response)
            })

        }

    }

    clearForm = () => {
        this.setState({ first_name: '', last_name: '', street_address: '', city: '', zip: '', mobile: '', email: '' })
    }

    changeFirstName = (event) => {
        this.setState({ first_name: event.target.value })
    }
    changeLastName = (event) => {
        this.setState({ last_name: event.target.value })
    }
    changeStreetAddress = (event) => {
        this.setState({ street_address: event.target.value })
    }
    changeCity = (event) => {
        this.setState({ city: event.target.value })
    }
    changeZip = (event) => {
        this.setState({ zip: event.target.value })
    }
    changeMobile = (event) => {
        this.setState({ mobile: event.target.value })
    }
    changeEmail = (event) => {
        this.setState({ email: event.target.value })
    }

    render() {
        function slugify(text) {
            const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
            const to = "aaaaaeeeeeiiiiooooouuuunc------"

            const newText = text.split('').map(
                (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

            return newText
                .toString()                     // Cast to string
                .toLowerCase()                  // Convert the string to lowercase letters
                .normalize('NFD')               // The normalize() method returns the Unicode Normalization Form of a given string.
                .trim()                         // Remove whitespace from both sides of a string
                .replace(/\s+/g, '-')           // Replace spaces with -
                .replace(/&/g, '-y-')           // Replace & with 'and'
                .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                .replace(/\-\-+/g, '-');        // Replace multiple - with single -
        }
        return (
            <Layout>
            <div className="section">
            <div className="content container">
                            <div className="columns" style={{ }}>
                                <div className="column is-8 is-offset-2">
                            <StaticQuery query={graphql`
                            query {
                                graphAPI{
                                    blogPosts(sort: "publishedAt:desc", limit: 1) {
                                        id
                                        title
                                        publishedAt
                                        description
                                    }
                                }
                            }`}
                            render={data => (
                                <>
                                {data.graphAPI.blogPosts.map( post =>(
                                    < >
                                    <h2 className="is-size-2">Latest Alert:</h2>
                                    <Link to={new Date(post.publishedAt).toISOString().substring(0,10)+"/"+slugify(post.title)} ><div className="has-text-centered notification is-primary has-text-white">
                                        <h2 className="is-marginless has-text-white">{post.title}</h2>
                                        <p className="has-text-white">Posted: {new Date(post.publishedAt).toDateString()}</p>
                                        <p className="has-text-white">{post.description ? post.description : null}</p>
                                    </div></Link>
                                    </ >
                                ))}

                                <hr />
                                
                                <div className="columns" style={{ }}>
                                    <div className="column is-7">

                                <p>Harris County MUD 286 has implemented a digital notification system to inform residents quickly of a water, sewer, drainage, or other District related event or situation.</p>

                                <h2>What will notifications look like?</h2>

                                <p>Residents registered for this free service will receive text and/or email notification explaining the basics of the situation with a short link to the news post on this site with more complete details.</p>

                                <h2>When will the notification system be used?</h2>

                                <p>Residents may receive a notification to inform them of situations such as water shutoffs for construction or repair, boil water notices, flood warnings, etc.</p>

                                <h2>Who will receive notifications?</h2>

                                <p>Residents will only receive notifications that apply to their street or zone. For example, a notice about a water outage scheduled for a specific street for repairs will only be sent to those affected in that area. This will limit any unnecessary notifications being sent to those it does not apply to.</p>
                                    </div>
                                    <div className="column is-4">
                                        <img src={alertimage} alt="example to text alert" />
                                    </div>
                                </div>


                                <iframe style={{ height: '480px', width: '100%'}} src="https://www.google.com/maps/d/u/0/embed?mid=1BZ_kk42oY8UerlNyheF5BkZdntmhKEk"></iframe>
                                </>

                            )} />


                            <h2>How to register?</h2>

                            <p>Residents may register additional phone numbers and email addresses for free using the form below. There are no limits or requirements, however it is recommended that at least two members per household register to receive notifications. Address information provided below will help determine your neighborhood and notification zone.</p>

                            <p></p>

                            <div dangerouslySetInnerHTML={{
                                __html: `
                                    <noscript class="notification is-danger" style="display: block;">This form requires a javascript enabled browser to function. Please enable javascript or switch to a compatible browser in order to use this form.</noscript>
                                `,
                            }}
                            ></div>

                        </div>
                    </div>
            <div className="columns" style={{marginBottom: '3rem', padding: '0 1rem'}}>
                <div className="column is-5 is-offset-2">
                    <form name="contact" onSubmit={this.submit}>
                        { this.state.formError.length !== 0 ? (<ul className="notification is-danger">{this.state.formError.map((error) => (
                            <li key={error} >{error}</li>
                        )) }</ul>): < ></ > }
                        { this.state.formSuccess === '' ? ( < ></ > ) : (<div className="notification is-success">{this.state.formSuccess}</div>)}


                        <div className="columns">
                        <div className="field column is-6">
                            <label htmlFor="first_name" className="label">First Name</label>
                            <div className="control">
                                <input className="input" type="text" name="first_name" placeholder="Enter your first name" onChange={this.changeFirstName} value={this.state.first_name} />
                            </div>
                        </div>
                        <div className="field column is-6">
                            <label htmlFor="last_name" className="label">Last Name</label>
                            <div className="control">
                                <input className="input" type="text" name="last_name" placeholder="Enter your last name" onChange={this.changeLastName} value={this.state.last_name} />
                            </div>
                        </div>
                        </div>
                        <div className="field">
                            <label htmlFor="email" className="label">Email</label>
                            <div className="control">
                                <input className="input" type="email" name="email" placeholder="Enter your email" onChange={this.changeEmail} value={this.state.email} />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="mobile" className="label">Mobile</label>
                            <div className="control">
                                <input className="input" type="text" name="mobile" placeholder="Enter your mobile number" onChange={this.changeMobile} value={this.state.mobile} />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="street_address" className="label">Street Address</label>
                            <div className="control">
                                <input className="input" type="text" name="street_address" placeholder="Enter your street address" onChange={this.changeStreetAddress} value={this.state.street_address} />
                            </div>
                        </div>
                        <div className="columns">
                        <div className="field column is-6">
                            <label htmlFor="city" className="label">City</label>
                            <div className="control">
                                <input className="input" type="text" name="city" placeholder="Enter your city" onChange={this.changeCity} value={this.state.city} />
                            </div>
                        </div>
                        <div className="field column is-6">
                            <label htmlFor="zip" className="label">Zip Code</label>
                            <div className="control">
                                <input className="input" type="text" name="zip" placeholder="Enter your 5 digit zip code" onChange={this.changeZip} value={this.state.zip} />
                            </div>
                        </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <input className="button is-primary is-rounded" type="submit" name="submit" value="Submit" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="columns" style={{ margin: '2rem 0 1rem'}}>
                        <div className="column is-8 is-offset-2 content">
                                <h2>Opting Out</h2>

                                <p>Residents may opt out of the service at any time by simply replying “Stop” to any texted notification.</p>

                                <h2>Privacy</h2>

                                <p>Resident contact information provided will only be used by the District and its consultants for resident notification purposes as described above and will not be made public, shared or sold.</p>

                                <h2>Additional Questions</h2>

                                <p>Residents may call our operations company, EDP, at (832) 467-1599 for assistance or to ask questions.</p>
                        </div>
                    </div>
                    </div>
                    </div>

            </Layout>
        )
    }
}

export default Homepage
